import api from "@/services/api/baseService";

class apiModuleTypeService {
  getActionList(action) {
    return api
      .get("/actions", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          type: action,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
  getStatusList(status) {
    return api
      .get("/statuses", {
        headers: {
          // Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          type: status,
        },
      })
      .then((response) => response)
      .catch((error) => error.response);
  }
}

export default new apiModuleTypeService();
